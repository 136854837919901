import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/switch/Switch.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/switch" storybook="https://workday.github.io/canvas-kit/?path=/story/components-inputs-switch--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=20198-33695&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6b926d2aeb5c0ce9ba7452962cddccbd/536c7/component-anatomy-switch-input.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "12.972972972972974%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Switch Input in its on state.",
              "title": "Image of a Switch Input in its on state.",
              "src": "/static/6b926d2aeb5c0ce9ba7452962cddccbd/50383/component-anatomy-switch-input.png",
              "srcSet": ["/static/6b926d2aeb5c0ce9ba7452962cddccbd/1efb2/component-anatomy-switch-input.png 370w", "/static/6b926d2aeb5c0ce9ba7452962cddccbd/50383/component-anatomy-switch-input.png 740w", "/static/6b926d2aeb5c0ce9ba7452962cddccbd/536c7/component-anatomy-switch-input.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Switch Label:`}</strong>{` Title of the input. Label position could be set to the top or left of the
Switch Input.`}</li>
        <li parentName="ol"><strong parentName="li">{`Switch:`}</strong>{` The element that when clicked, switches between two states.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`A Switch is used to enable or disable a mode, feature, or function.`}</li>
        <li parentName="ul">{`Switches have an immediate effect on the system.`}</li>
        <li parentName="ul">{`Switches only have 2 options: on or off.`}</li>
        <li parentName="ul">{`The Switch Label can be positioned in two places; above or left of the Switch for LTR languages.
Switch Labels are aligned to the right of the Switch for RTL languages.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use to turn an option on or off.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Consider using `}<a parentName="li" {...{
            "href": "/components/inputs/radio"
          }}>{`Radio Buttons`}</a>{` if users need to select one item from a
list of options.`}</li>
        <li parentName="ul">{`Consider using a `}<a parentName="li" {...{
            "href": "/components/inputs/checkbox"
          }}>{`Checkbox`}</a>{` if users need to confirm a statement such
as an agreement.`}</li>
        <li parentName="ul">{`Consider using a Checkbox or Multi-select Prompt if users can select multiple items from a list of
options.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each Switch must have a focus indicator that is highly visible against the background and against
the non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more
information.`}</p>
      <p>{`Checkboxes must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus a Switch`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Space`}</inlineCode>{`: change the state of a focused switch`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Switch must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The accessible name (label) of the focused Switch`}</li>
        <li parentName="ul">{`The state of the Switch`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <p>{`No design annotations needed for Switch`}</p>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Switch must have a `}<inlineCode parentName="li">{`required`}</inlineCode>{` attribute when the Switch is required for submission.`}</li>
        <li parentName="ul">{`Switch must have an `}<inlineCode parentName="li">{`aria-invalid=”true”`}</inlineCode>{` attribute when it has an error condition`}</li>
        <li parentName="ul">{`[Included in component]`}{` Switch is based on an HTML Checkbox Input, which already supports the “on”
and “off” states of this component without the `}<inlineCode parentName="li">{`aria-checked`}</inlineCode>{` property.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Switch must have an ARIA `}<inlineCode parentName="li">{`role=“switch”`}</inlineCode>{` attribute augmenting the checkbox
input element.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`<label>`}</inlineCode>{` element is required with a `}<inlineCode parentName="li">{`for`}</inlineCode>{` attribute referencing the
unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` value of the associated Switch.`}</li>
        <li parentName="ul">{`[Included in component]`}{` A `}<inlineCode parentName="li">{`disabled`}</inlineCode>{` attribute is set when the Switch is disabled.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Switch Labels are written in title case.`}</li>
        <li parentName="ul">{`Use labels such as “on” and “off” or “enable” and “disable” to communicate the state of the
Switch.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/wUewAjqFBnLx2ka2B49FUR/%F0%9F%9A%A7-BETA%3A-Mobile-UI-Components-%F0%9F%9A%A7?type=design&node-id=3197%3A41552&mode=design&t=mortUIPu1vSY3rkW-1" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f39a575b9959cb7e21e8f3bddabe5e75/0ddab/switch-anatomy-iOS.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.162162162162165%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAACE4AAAhOAFFljFgAAAAT0lEQVR42q2OQQqAIBQFvf95gq7hplWraBmBGJFi70/hBRTqrd8M4+hZLsgvIGteXZcQI28BlfsHocQZE+t+Yfal8IUrHg6mcWbwsZa2nA/WDjyo3XKQYwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Breakdown of different elements of a Switch",
                "title": "Breakdown of different elements of a Switch",
                "src": "/static/f39a575b9959cb7e21e8f3bddabe5e75/50383/switch-anatomy-iOS.png",
                "srcSet": ["/static/f39a575b9959cb7e21e8f3bddabe5e75/1efb2/switch-anatomy-iOS.png 370w", "/static/f39a575b9959cb7e21e8f3bddabe5e75/50383/switch-anatomy-iOS.png 740w", "/static/f39a575b9959cb7e21e8f3bddabe5e75/0ddab/switch-anatomy-iOS.png 2220w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` Interactive area that the handle moves across.`}</li>
          <li parentName="ol"><strong parentName="li">{`Handle:`}</strong>{` Affordance for the toggle direction.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use a Switch to enable users to toggle functions on and off that are applied immediately without
the need for any further validation (e.g., in Settings).`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-consider-something-else"
        }}>{`When to Consider Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Consider using alternative selection controls such as a Radio Button for selecting a singular item
or a Checkbox for multiple items. These selection controls are lost without saving.`}</li>
        </ul>
        <h2 {...{
          "id": "behaviors"
        }}>{`Behaviors`}</h2>
        <p>{`A function has successfully been altered when the handle moves across the container, changing the
underlying status.`}</p>
        <h3 {...{
          "id": "notification-states"
        }}>{`Notification States`}</h3>
        <p>{`Because a Switch can alter functionality, a Switch supports both warning and error states.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/548bfd4f3d9314571a3ba94675263e9a/0ddab/switch-notification-states-iOS.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "15.945945945945947%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAACE4AAAhOAFFljFgAAAAU0lEQVR42mP4jwP8A5N//2889/z/wxev/v/99vb/tw8v/xMCDDgN/AcxduupJ/9v33v+/8+HV/+/vnpGgYFQcsOxF/8f3n/9/8/7d/+/vnxF0EAAqo3rPe++zwcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Switches showing warning and error states",
                "title": "Switches showing warning and error states",
                "src": "/static/548bfd4f3d9314571a3ba94675263e9a/50383/switch-notification-states-iOS.png",
                "srcSet": ["/static/548bfd4f3d9314571a3ba94675263e9a/1efb2/switch-notification-states-iOS.png 370w", "/static/548bfd4f3d9314571a3ba94675263e9a/50383/switch-notification-states-iOS.png 740w", "/static/548bfd4f3d9314571a3ba94675263e9a/0ddab/switch-notification-states-iOS.png 2220w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <p>{`Coming soon...`}</p>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`The Switch component must have an `}<inlineCode parentName="li">{`AccessibilityLabel`}</inlineCode>{` that matches the visible label displayed with the switch.`}</li>
          <li parentName="ul">{`The Switch component and its visible label must be grouped as one focus stop for assistive technologies.`}</li>
          <li parentName="ul">{`When an error or alert is displayed, the error/alert text must be included in the `}<inlineCode parentName="li">{`AccessibilityValue`}</inlineCode>{` of the Switch component.`}</li>
        </ul>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <p>{`Coming soon...`}</p>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/wUewAjqFBnLx2ka2B49FUR/%F0%9F%9A%A7-BETA%3A-Mobile-UI-Components-%F0%9F%9A%A7?type=design&node-id=3197%3A41552&mode=design&t=mortUIPu1vSY3rkW-1" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/14fcab3bc208d617b45513e354701e9b/0ddab/switch-anatomy-Android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.2972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAACE4AAAhOAFFljFgAAAAUUlEQVR42mP4jwf8+/Hr//+zD/8/uHL9/9t3b/4TAxjwGvjv3///v37/v/fi8/+nb7/+///zN0SMXAP/QvUuPvft/5lbX4Cuvff/368/eA0EADAL7CFHiG8fAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Breakdown of different elements of a Switch",
                "title": "Breakdown of different elements of a Switch",
                "src": "/static/14fcab3bc208d617b45513e354701e9b/50383/switch-anatomy-Android.png",
                "srcSet": ["/static/14fcab3bc208d617b45513e354701e9b/1efb2/switch-anatomy-Android.png 370w", "/static/14fcab3bc208d617b45513e354701e9b/50383/switch-anatomy-Android.png 740w", "/static/14fcab3bc208d617b45513e354701e9b/0ddab/switch-anatomy-Android.png 2220w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` Interactive area that the handle moves across.`}</li>
          <li parentName="ol"><strong parentName="li">{`Handle:`}</strong>{` Affordance for the toggle direction.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use a Switch to enable users to toggle functions on and off that are applied immediately without
the need for any further validation (e.g., in Settings).`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-consider-something-else-1"
        }}>{`When to Consider Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Consider using alternative selection controls such as a Radio Button for selecting a singular item
or a Checkbox for multiple items. These selection controls are lost without saving.`}</li>
        </ul>
        <h2 {...{
          "id": "behaviors-1"
        }}>{`Behaviors`}</h2>
        <p>{`A function has successfully been altered when the handle moves across the container, changing the
underlying status.`}</p>
        <h3 {...{
          "id": "notification-states-1"
        }}>{`Notification States`}</h3>
        <p>{`Because a Switch can alter functionality, a Switch supports both warning and error states.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/5dec2a90312d39c029b949d15f57bc50/0ddab/switch-notification-states-Android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "16.216216216216214%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAACE4AAAhOAFFljFgAAAAUElEQVR42qXMMQ5AMBhA4d7/IJ2cAiNbS0qi/vyRGNob4EnsdPCGN36Gl67nB7aNuEWR0JG2QCnzDZ5UTaQflLSOZJn+g7becXNGvCeJFsEbxiDpfzjHWlIAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Switches showing warning and error states",
                "title": "Switches showing warning and error states",
                "src": "/static/5dec2a90312d39c029b949d15f57bc50/50383/switch-notification-states-Android.png",
                "srcSet": ["/static/5dec2a90312d39c029b949d15f57bc50/1efb2/switch-notification-states-Android.png 370w", "/static/5dec2a90312d39c029b949d15f57bc50/50383/switch-notification-states-Android.png 740w", "/static/5dec2a90312d39c029b949d15f57bc50/0ddab/switch-notification-states-Android.png 2220w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "pressed-state"
        }}>{`Pressed State`}</h3>
        <p>{`When pressing on a Switch, the handle enlarges to fill more of the container.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/542780d5f2dedbc7fda53b519f5e3e28/0ddab/switch-pressed-states-Android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "9.729729729729728%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAACE4AAAhOAFFljFgAAAAL0lEQVR42mP4jwX8+wehLz//+f/Yve//v375/P/oseP/f/3+/Z8QYCBk4FESDQQAbyacUv+brj8AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Switch with large handle upon press",
                "title": "Switch with large handle upon press",
                "src": "/static/542780d5f2dedbc7fda53b519f5e3e28/50383/switch-pressed-states-Android.png",
                "srcSet": ["/static/542780d5f2dedbc7fda53b519f5e3e28/1efb2/switch-pressed-states-Android.png 370w", "/static/542780d5f2dedbc7fda53b519f5e3e28/50383/switch-pressed-states-Android.png 740w", "/static/542780d5f2dedbc7fda53b519f5e3e28/0ddab/switch-pressed-states-Android.png 2220w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun SwitchUiComponent(
    modifier: Modifier = Modifier,
    checked: Boolean,
    showIcon: Boolean = false,
    semanticState: SemanticState = SemanticState(),
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
    onCheckedChange: ((Boolean) -> Unit) = {},
) {
`}</code></pre>
        <h3 {...{
          "id": "parameters"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`modifier`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Modifier to be applied to the layout of the checkbox.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`checked`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Current state of the switch.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`showIcon`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Controls if icons will be shown within the thumb of the switch. Defaults to false. Checkmark icon will be shown when switch is checked. Cross icon will be shown when it is NOT.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`semanticState`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`SemanticState of the switch, i.e. Normal, Warning, Error, and Enabled states. Defaults to enabled and normal.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`interactionSource`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the MutableInteractionSource representing the stream of interactions for this Switch.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onCheckedChange`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Callback for when the switch is clicked on. Will pass back the boolean representing the new value of if the switch is checked or not.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Coming soon...`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <p>{`Coming soon...`}</p>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      